<!--
 * @Author: 周小雨1224 1249855464@qq.com
 * @Date: 2023-07-31 14:12:46
 * @LastEditors: zhouxiaoyu 
 * @LastEditTime: 2024-04-30 15:17:51
 * @FilePath: \officialwebsite\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: LiuBangHua 19922217004@163.com
 * @Date: 2023-07-31 14:12:46
 * @LastEditors: 周小雨1224 1249855464@qq.com
 * @LastEditTime: 2023-08-01 10:12:36
 * @FilePath: \officialwebsite\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <div class="head">
      <div class="head-con">
        <div class="head-Log">
          <img src="./static/home/u64.png" alt="" />
        </div>
        <div class="head-log2">
          <img :src="logoSrc" alt="" class="afterimg" />
        </div>
        <div class="head-nav">
          <el-menu
            :default-active="this.$store.state.activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#fff"
            text-color="rgb(102, 102, 102)"
            @select="handleSelect"
            active-text-color="rgb(78, 120, 240)"
          >
            <span v-for="item in homeDataListHeader" :key="item.column_id">
              <el-menu-item :index="item.column_id.toString()">{{
                item.column_name
              }}</el-menu-item>
            </span>
          </el-menu>
        </div>
      </div>
    </div>

    <router-view class="neirong" @toChangeRoute="handleToChangeRoute" />
    <div class="foot">
      <div class="foot-con">
        <span>{{ homeDataListFooter.footer_gwa }}</span
        >&nbsp;&nbsp;

        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="blank"
          class="spanright"
          >{{ homeDataListFooter.footer_icp }}</a
        >&nbsp;&nbsp;
        <div style="height: 20px; width: 20px; display: flex">
          <img
            src="../src/static/home/gongan.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
        &nbsp;&nbsp;
        <a
          :href="`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${footer_bean}`"
          target="blank"
          class="spanright"
          style="color: #fff"
          >{{ homeDataListFooter.footer_bean }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getHeadTail } from "@/api/homeapi.js";
export default {
  data() {
    return {
      // activeIndex: this.$store.state.activeIndex,
      homeDataListHeader: [],
      homeDataListFooter: [],
      footer_bean: "",
      logoSrc: "",
    };
  },
  mounted() {
    const userAgent = navigator.userAgent.toLowerCase();
			if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
				// 移动端
				console.log('移动端')
				location.href = "https://www.cqsxzg.com/mobile/#"
				return

			} else {
				// pc端
				console.log('PC端')
				this.getHeadTail()
			}
    // this.getHeadTail();
    // setTimeout(() => {
    //   console.log("8888444444444", this.description);
    //   console.log("8888444444444", this.keywords);
    // }, 300);
  },
  metaInfo() {
    return {
      title: "水熊官网",
      meta: [
        { 
          name: 'description', 
          content: this.description
        },
        { 
          name: 'keywords', 
          content: this.keywords
        },
        // 其他meta标签...
      ]
    };
  },
  computed: {
    description() {
      return this.$store.state.footer.description;
    },
    keywords() {
      return this.$store.state.footer.keyword;
    },
  },
  methods: {
    getHeadTail() {
      getHeadTail().then((res) => {
        console.log("res", res);
        this.homeDataListFooter = res.data.result.footer;
        this.homeDataListHeader = res.data.result.header;
        this.footer_bean = res.data.result.footer.footer_bean.replace(
          /[^\d]/g,
          " "
        );
        this.logoSrc = res.data.result.footer.logo;
        this.$store.commit("setFooter", res.data.result.footer);
      });
    },
    handleToChangeRoute(path) {
      // console.log("path", path);
      this.$store.commit("setActiveIndex", path);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == "1") {
        if (this.$store.state.activeIndex == "1") {
          return;
        } else {
          this.$store.commit("setActiveIndex", key);
          this.$router.push({
            path: "/HomePage",
          });
        }
      } else if (key == "2") {
        if (this.$store.state.activeIndex == "2") {
          return;
        } else {
          this.$store.commit("setActiveIndex", key);
          this.$router.push({
            path: "/ProductCenter",
          });
        }
      } else if (key == "3") {
        if (this.$store.state.activeIndex == "3") {
          return;
        } else {
          this.$store.commit("setActiveIndex", key);
          this.$router.push({
            path: "/StudyCenter",
          });
        }
      } else if (key == "4") {
        if (this.$store.state.activeIndex == "4") {
          return;
        } else {
          this.$store.commit("setActiveIndex", key);
          this.$router.push({
            path: "/AnnouncementUpdates",
          });
        }
      } else if (key == "5") {
        if (this.$store.state.activeIndex == "5") {
          return;
        } else {
          this.$store.commit("setActiveIndex", key);
          this.$router.push({
            path: "/ContactUs",
          });
        }
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>

<style lang="scss" scoped>
.head {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 10;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .head-con {
    // width: 1920px;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    // background: rgb(78, 120, 240);

    .head-Log {
      width: 40px;
      height: 40px;
      // background: red;
      float: left;
      // margin-left: 350px;
      margin-top: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .head-log2 {
      width: 120px;
      height: 36px;
      float: left;
      margin: 24px 0 0 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .head-nav {
      float: right;
      width: 760px;
      height: 100%;
      // margin-left: 315px;
      margin-left: 200px;
      box-sizing: border-box;
      padding: 0 0 20px 0;
      background: #fff;
      ::v-deep .el-menu.el-menu--horizontal {
        border: none;
      }
      ::v-deep .el-menu-item {
        height: 60px;
        line-height: 80px;
        float: left;
        margin-left: 54px;
        color: rgb(102, 102, 102);
        font-size: 16px;

        // border-radius: 5px;
      }
      ::v-deep .el-menu-item:hover {
        background: #fff !important;
        color: rgb(102, 102, 102);
      }
      ::v-deep .el-menu--horizontal > .el-menu-item:hover {
        background: #fff !important;
        color: #fff !important;
      }

      ::v-deep .el-menu-item.is-active {
        background: #fff !important;
        // border-radius: 5px;
        font-size: 16px;
        border-bottom: 3px solid rgb(78, 120, 240);
      }
    }
  }
}

#app::-webkit-scrollbar {
  width: 0;
  height: 0;
}
#app {
  overflow: hidden;
  scrollbar-width: none;
}

.neirong {
  margin-top: 80px;
}

.foot {
  width: 100%;
  height: 60px;
  background: rgb(51, 51, 51);

  .foot-con {
    width: 1200px;
    height: 100%;
    background: rgb(51, 51, 51);
    margin: 0 auto;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    .spanright {
      color: rgb(78, 120, 240);
      text-decoration: none;
    }
  }
}
.afterimg {
  position: relative;
}
.afterimg:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: url("./static/produce/loading.gif");
  background-size: 100% 100%;
}
</style>
